// Libs.
import React, {useEffect, useState} from 'react';
// Deps.
import CheckoutComplete from '../../../components/checkout/checkout-complete';
import SEO from '../../../components/layout/seo';
// Custom Libs.
import { getOrderDetailsFromQueryString } from '../../../libs/commerce/checkout-utils';
// Assets.
import { checkoutPage } from '../../../locale/locale.json';
import AppLayout from "../../../components/layout/AppLayout";
import appAliases from "../../../data/appAliases";
import NavigationDrawer, {useDrawer} from "../../../hooks/useNavigationDrawer";
import navigate from '../../../libs/navigate';
import ContactUs from "../../../components/contact-us/contact-us";

const CheckoutCompletePage = (props) => {
  const orderDetails = getOrderDetailsFromQueryString(props.location.href);
  const [open, setOpen] = useState(false);
  const [drawers] = useDrawer();
  useEffect(() => {
    // just for animation.
    setOpen(true);
  }, []);
  return (
    <AppLayout >
      <SEO title="Order Complete" />
      <NavigationDrawer drawerStatus={open} header={'All classes'} onClose={() => navigate(appAliases.appClasses)}>
        <CheckoutComplete
          cartType="loft"
          pageTitle={'Order Confirmation'}
          displayMessage={checkoutPage.labels.confirmation.classes}
          orderDetails={orderDetails}
        />
      </NavigationDrawer>
      <NavigationDrawer menuHide={true} drawerStatus={drawers.includes('Contactus')}>
        <ContactUs close={false}/>
      </NavigationDrawer>
    </AppLayout>
  );
};

export default CheckoutCompletePage;
